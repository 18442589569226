import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import QRCode from "react-qr-code";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  TextField,
  CircularProgress,
  Box,
  Tooltip,
  Button,
} from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PaymentHandler = () => {
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const [copied, setCopied] = useState(false);
  const [timer, setTimer] = useState({ minutes: 15, seconds: 0 })
  const [config, setConfig] = useState({
    orderID: params.get("orderID"),
    paymentStatus: "Awaiting Payment",
    loader: true,
    convertedAmount: 0,
    state: true,
    address: "",
    redirectURL: params.get("redirectURL"),
    callbackURL: params.get("callbackURL"),
    source: params.get("source"),
  });

  const fetchData = async () => {

    /** @desc Payment timer logic */
    const CountDownTimer = setInterval(() => {
      if (timer.seconds > 0) {
        timer.seconds -= 1
        setTimer({
          minutes: timer.minutes,
          seconds: timer.seconds
        })
      }
      if (timer.seconds === 0) {
        if (timer.minutes === 0) {
          clearInterval(CountDownTimer)
        } else {
          timer.minutes -= 1
          timer.seconds = 59
          setTimer({
            minutes: timer.minutes,
            seconds: timer.seconds
          })
        }
      }
    }, 1000);

    if (config.state) {
      // Call api to insert payment request record in database
      let response = await axios.get(
        `${process.env.REACT_APP_INSERTURL}${location.search}`
      );
      let data = await response.data;

      if (data.status === "success") {
        // update payment params after api response
        setConfig({
          ...config,
          state: false,
          convertedAmount: data.amount,
          loader: false,
          paymentStatus: "Awaiting Payment",
          address: data.address,
          coinLabel: data.coinLabel,
          partialPayment: 0,
        });

        /** @desc time interval method to check if partial payment is done */
        const checkPartialPayment = setInterval(async () => {
          let resPartial = await axios.get(
            `${process.env.REACT_APP_PARTIALPAYMENT}?orderID=${config.orderID}&address=${data.address}&coinLabel=${data.coinLabel}&kirinAmount=${data.amount}&source=${config.source}&callbackURL=${config.callbackURL}`
          );
          let partialPayData = await resPartial.data;

          if (partialPayData.status === "success") {
            config.partialPayment = partialPayData.data.amountReceived
            setConfig({
              ...config,
              state: false,
              convertedAmount: data.amount,
              loader: false,
              paymentStatus: "Awaiting Payment...",
              address: data.address,
              coinLabel: data.coinLabel,
              partialPayment: partialPayData.data.amountReceived,
            });
          }
        }, 5000);

        /** @desc get payment status */
        let response = await axios.get(
          `${process.env.REACT_APP_PAYMENTSTATUS}?orderID=${config.orderID}&address=${data.address}&coinLabel=${data.coinLabel}&kirinAmount=${data.amount}&source=${config.source}&callbackURL=${config.callbackURL}`
        );
        let paymentData = await response.data;

        if (paymentData) {
          if (paymentData.messageType === "success") {
            toast.success(paymentData.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (paymentData.messageType === "warning") {
            toast.warn(paymentData.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          setConfig({
            ...config,
            loader: false,
            paymentStatus: "Redirecting! Please wait...",
            redirectURL: paymentData.redirecturl,
            convertedAmount: data.amount,
          });

          /** Redirect if payment status is updated */
          setTimeout(function () {

            clearInterval(checkPartialPayment);

            if (config.redirectURL !== "" && config.redirectURL !== null) {
              if (paymentData.jsonData === "others") {
                window.location.replace(
                  `${config.redirectURL}?orderID=${config.orderID}&address=${data.address}&amount=${data.amount}&source=${config.source}&orderStatus=${paymentData.orderStatus}&transactionID=${paymentData.txID}`
                );
              } else if (paymentData.jsonData === "woo-commerce") {
                window.location.replace(
                  `${config.redirectURL}/${config.orderID}`
                );
              }
            } else {
              if (paymentData.jsonData === "others") {
                window.location.replace(
                  `${paymentData.redirecturl}?orderID=${config.orderID}&address=${data.address}&amount=${data.amount}&source=${config.source}&transactionID=${paymentData.txID}`
                );
              } else if (paymentData.jsonData === "woo-commerce") {
                window.location.replace(
                  `${paymentData.redirecturl}/${config.orderID}`
                );
              }
            }
          }, 5000);


        }
      }
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {config.loader ? (
        <div className="displayCircularLoader">
          <Box sx={{ display: "flex" }} className="circularLoader">
            <CircularProgress size="6rem" />
          </Box>
        </div>
      ) : (
        <div className="handlerContainer">

          <div className="handlerBody">
            <h1>TiggerPay PAYMENT GATEWAY</h1>
            <p>Please don't close this window until the system completes your transaction...</p>
            <div className="paymentDetails">
              <div className="displayDetails">
                <h1>Order ID :{config.orderID}</h1>
                <h1>Amount : {config.convertedAmount} {config.coinLabel.toLowerCase() === 'usdt' ? `Tether (USDT)` : (config.coinLabel.toLowerCase() === 'crc' ? `CRC` : `KIRIN`)}</h1>
              </div>
              <div className="handlerBodyDesc">
                {timer.minutes === 0 && timer.seconds === 0
                  ? <p>Time up! Please generate a new payment link. Redirecting Shortly...</p>
                  : <p>You have time left to transfer {config.coinLabel.toLowerCase() === 'usdt' ? `Tether (USDT)` : (config.coinLabel.toLowerCase() === 'crc' ? `CryptoClassic` : `KIRIN`)}: {timer.minutes < 10 ? `0${timer.minutes}` : timer.minutes}:{timer.seconds < 10 ? `0${timer.seconds}` : timer.seconds} minutes</p>
                }

              </div>
              <div className="bodyDetails">
                <Tooltip title={config.address} arrow>
                  <div className="qrCode">
                    <QRCode value={config.address} />
                  </div>
                </Tooltip>

                <div className="paymentContent">
                  <div className="paymentStatus">
                    <Box sx={{ width: "100%" }} className="paymentStatus">
                      <LinearProgress />
                      <p>{config.paymentStatus}</p> <p>{config.partialPayment !== '' && parseFloat(config.partialPayment) > 0 ? "Partial Payment Received: " + config.partialPayment : ""}</p>
                    </Box>
                  </div>
                  <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />

                  <TextField
                    className="inputField"
                    disabled
                    id="outlined-disabled"
                    label="Address"
                    defaultValue={config.address}
                  />
                  <div className="copyButton">
                    <CopyToClipboard
                      text={config.address}
                      onCopy={() => {
                        setCopied(true);
                      }}
                    >
                      <Button style={{ marginTop: "10px" }} variant="contained">
                        Copy Address
                      </Button>
                    </CopyToClipboard>
                    <p
                      style={{
                        textAlign: "center",
                        color: "#169727",
                        margin: "10px 0 0 0",
                      }}
                    >
                      {copied ? "Address Copied" : ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      )}
    </>
  );
};

export default PaymentHandler;
