import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import { Button, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
const PaymentRequest = () => {

  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);

  const [recaptchaToken, setRecaptchaToken] = useState("");
  const navigate = useNavigate();
  const [txParams,] = useState({
    orderID: params.get("orderID"),
    source: params.get("source"),
    productAmount: params.get("productAmount"),
    coinLabel: params.get("coinLabel"),
    redirectURL: params.get("redirectURL"),
    callbackURL: params.get("callbackURL"),
  });

  const [field, setField] = useState({
    orderID: txParams.orderID,
    source: txParams.source,
    productAmount: txParams.productAmount,
    coinLabel: null,
    redirectURL: txParams.redirectURL,
    callbackURL: txParams.callbackURL,
  });

  const [cryptoRateUsd, setCryptoRateUsd] = useState({ USDT: 1, KRN: 0.00000288, CRC: 0.005 });

  const currencyList = {
    USDT: 'Tron USDT (Tether)',
    KRN: 'Kirin Token',
    CRC: 'CryptoClassic Coin',
  };

  const redirectRoute = async () => {

    if (!(field.coinLabel in currencyList)) {
      alert(`Invalid Currency ${field.coinLabel} provided. Please contact support team!`);
      return false;
    }

    if (recaptchaToken) {
      try {
        const response = await axios.post(process.env.REACT_APP_RECAPTCHA, {
          recaptchaToken,
        });

        if (response.data.success) {
          // reCAPTCHA verification succeeded, proceed with form submission
          let navigateURL = `/handler?orderID=${field.orderID}&productAmount=${field.productAmount}&coinLabel=${field.coinLabel}&source=cryptogoshop_ecomm`;
          navigateURL += (field.redirectURL != null ? `&redirectURL=${field.redirectURL}` : ``);
          navigateURL += (field.callbackURL != null ? `&callbackURL=${field.callbackURL}` : ``);

          // console.log(navigateURL);
          navigate(navigateURL);
        } else {
          // reCAPTCHA verification failed
          console.error("reCAPTCHA verification failed");
          alert("reCAPTCHA verification failed");
        }
      } catch (error) {
        console.error("Error verifying reCAPTCHA:", error);
        alert("Error verifying reCAPTCHA!");
      }
    } else {
      alert("Please verify reCAPTCHA!");
    }
    return false;
  };

  useEffect(() => {
    handleInput({ target: { name: "coinLabel", value: txParams.coinLabel.toUpperCase() } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchCRCRate = async () => {
      try {
        const response = await fetch('https://api.coingecko.com/api/v3/coins/crypto-classic?x_cg_demo_api_key=CG-5QWSfwmuYmDWhx4cSL989XmZ');
        if (!response.ok) {
          throw new Error('Network response error!');
        }
        const data = await response.json();
        // const data = { id: 1, market_data: { current_price: { usd: 0.00040015 } } };
        if (data.id) setCryptoRateUsd({ ...cryptoRateUsd, CRC: data.market_data.current_price.usd });
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        // setLoading(false);
      }
    };

    fetchCRCRate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleInput = (e) => {
    setField({ ...field, [e.target.name]: e.target.value });
  };

  useEffect(() => {

    if ((field.coinLabel) === null) return;

    let total_usd = cryptoRateUsd[txParams.coinLabel.toUpperCase()] * txParams.productAmount;

    // console.log(cryptoRateUsd[txParams.coinLabel.toUpperCase()], txParams.productAmount, total_usd);

    // console.log(field.coinLabel);
    let crypto_total = total_usd / cryptoRateUsd[field.coinLabel.toUpperCase()];

    crypto_total = (Math.round(crypto_total * 100000000) / 100000000).toFixed(8);

    // console.log(crypto_total, field.coinLabel.toUpperCase());

    setField({ ...field, productAmount: crypto_total });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.coinLabel]);

  return (
    <>
      <div className="container">
        <div>
          <Box className="container_outside" component="form" sx={{ "& > :not(style)": { m: 1, width: "30ch" }, }} noValidate autoComplete="off" >
            <div><span>Order ID: </span><span style={{ fontWeight: 'bold' }}>{txParams.orderID}</span></div>
            <div><span>Requested Amount: </span><span style={{ fontWeight: 'bold' }}>{txParams.productAmount}</span></div>

            {/* <TextField id="order_id" label="Order ID" name="orderID" onBlur={handleInput} variant="standard" style={{ display: 'none' }} /> */}
            {/* <TextField id="amount" label="Amount (USD)" name="productAmount" onBlur={handleInput} variant="standard" style={{ display: 'none' }} /> */}
            {/* <TextField id="coin_label" label="Coin Label" name="coinLabel" onBlur={handleInput} variant="standard" /> */}

            <span style={{ fontWeight: 'bold' }}>Payment Options:</span>
            <RadioGroup row aria-label="coinLabel" id="coinLabel" name="coinLabel" value={field.coinLabel} onChange={handleInput}>
              {Object.entries(currencyList).map(([key, value]) => (
                <FormControlLabel key={key} value={key} control={<Radio />} label={value} />
              ))}

              {/* <FormControlLabel value="USDT" control={<Radio />} label="Tron USDT (Tether)" /> */}
              {/* <FormControlLabel value="KRN" control={<Radio />} label="Kirin Token" />
              <FormControlLabel value="CRC" control={<Radio />} label="CryptoClassic Coin" /> */}
            </RadioGroup>

            <div style={{ textAlign: 'center' }}><span>You Will Pay: </span><span style={{ fontWeight: 'bold', marginBottom: '16px' }}>{field.productAmount} {field.coinLabel}</span></div>

            <ReCAPTCHA sitekey="6LccqhUpAAAAAB6v1bmy91SGL24nsHDVhWLgVUof" onChange={(token) => setRecaptchaToken(token)} />

            <div style={{ textAlign: 'center' }}><Button variant="contained" onClick={redirectRoute} style={{ padding: '15px 25px' }}> Pay Now </Button></div>
          </Box>
        </div>
      </div >
    </>
  );
};

/* Original code backup */
// return (
//   <>
//     <div className="container">
//       <div>
//         <Box className="container_outside" component="form"
//           sx={{ "& > :not(style)": { m: 1, width: "30ch" }, }} noValidate autoComplete="off" >
//           <TextField id="standard-basic" label="Order ID" name="orderID" onBlur={handleInput} variant="standard" />
//           <TextField id="standard-basic" label="Amount (USD)" name="productAmount" onBlur={handleInput} variant="standard" />
//           <TextField id="standard-basic" label="Coin Label" name="coinLabel" onBlur={handleInput} variant="standard" />
//           <Button variant="contained" onClick={redirectRoute}> Checkout </Button>
//         </Box>
//       </div>
//     </div >
//   </>
// );

export default PaymentRequest;
